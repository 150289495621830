.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.badge {
  background-color: #9ae6b4;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
input {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
}

table {
  border-spacing: 0;
  border: 1px solid #ededed;
  width: 100%;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}
table tr:nth-child(odd) {
  background-color: #ffffff;
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}
table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

.grid-child {
  border: 2px solid #f0f0f0;
}  

.flex-container {
  display: flex;
  padding: 30px;
}

.flex-child {
  flex: 1;
  border: 2px solid #f0f0f0;
}  

.flex-child:first-child {
  margin-right: 60px;
} 

.chart-container {
  /* position: absolute; */
  width: 100%;
}

.chart {
  float: left;
  width: 500px;
  background-color: #ffffff;
  border: 2px solid #f0f0f0;
  padding: 10px;
  margin: 30px;
} 

@media screen and (max-width: 600px) {
  .chart {
    width: 75%;;
  }
}

.nav-open {
  display: block !important;
  position: absolute;
  width: 100%;
  top: 70px;
  background-color: #00aaff;
  padding: 20px;
  z-index: 1;
  text-align: center;
}

.nav-open a {
  padding: 15px;
}

.form-row {
  margin-bottom: 0px;
}
.form-label {
  display: inline-block;
  width: 100px;
  text-align: left;
}
.form-input {
  margin-bottom: 5px;
  font-size: 1rem;
  width: 65%;
}
.form-select {
  display: inline-block;
  font-size: 1.1em;
  width: 65%;
}

.panel {
  border-radius: 25px;
  /*border: 2px solid #0033ff; */
  border: 2px solid #d0d0d0;
  padding: 20px;
  background: #f0f0f0;
  margin-bottom: 20px;
}


.entry-field {
  display: inline-block;
  margin-right: 20px;
}