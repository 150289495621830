@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap');


.container {
    font-size: 14px;
    position: fixed;
    z-index: 10;
    font-family: 'ibm plex sans', sans-serif;

  }
  
  .top-right {
    top: 1rem;
    right: 1rem;
    animation: toast-in-right .7s;
  }
  
  .notification {
    margin-bottom: 1rem;
    border-radius: 4px;
    box-shadow: 0 0 10px #999;
    color: #000;
    opacity: 0.9;
    transition: .3s ease;
  }
  
  .notification:hover {
    box-shadow: 0 0 12px #fff;
    opacity: 1;
  }
  
  .toast {
    height: 50px;
    width: 365px;
    color: #fff;
    padding: 20px 15px 10px 10px;
  }
  
  .container button {
    float: right;
    background: none;
    border: none;
    color: #fff;
    opacity: 0.8;
    cursor: pointer;
  }
  
  .title {
    font-weight: 700;
    font-size: 16px;
    font-family: 'ibm plex sans', sans-serif;
    text-align: left;
    margin-top: 0;
    margin-bottom: 6px;
    width: 300px;
    height: 18px;
  }
  
  .description {
    margin: 0;
    text-align: left;
    font-family: 'ibm plex sans', sans-serif;

  }
  
  @keyframes toast-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }