@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap');

body{
  background: rgb(34,193,195);
  background: url("./wave-login-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "ibm plex sans";
  margin: 0;
}

html{
  min-height: 100%;
  font-size: 100%;
}

#form{
  max-width: 500px;
  min-width: 300px;
  width: 30%;
  height: 60%;
  margin: 100px auto;
  background-color: #f6f6f6;
  border-radius: 25px;
  

}

#headerTitle{
  text-align: center;
  font-family: 'ibm plex sans', sans-serif;
  padding: 2rem 0;
  margin: 0;
  font-size: 2rem;
}

.main {
  margin: 12px;
}

.row{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  max-width: 100%;
  font-size: 1.1rem;
  font-family: 'ibm plex sans', sans-serif;

}

.row input{
  width: 80%;
  box-sizing: border-box;
	border: none;
  font-size: 1.2rem;
  padding-left: 1.2rem;
  padding-bottom: 1rem;
  box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
  transition: box-shadow 0.2s ease-in;
}

.row input:focus{
   box-shadow: inset 0px -3px 0px 0px #0066ff;
   outline: none;
}

.row input::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder{
  opacity: 0;
}

.row label{
  width: 80%;
  padding-bottom: 0.5rem;
  color: rgba(128, 128, 128, 0.9);
}

.row button{
  border-radius: 25px;
  width: 80%;
  height: 40px;
  font-size: 1.2rem;
  color: white;
  font-weight: 700;
  background: #0066ff;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  font-family: 'ibm plex sans', sans-serif;

}

.check-out {
  background-color: #EF476F !important;
}

.check-in {
  background-color: #06D6A0 !important;
}

.row button:hover{
  opacity: 0.8;
}

.normalbutton {
  border-radius: 25px;
  height: 30px;
  font-size: 1.1rem;
  color: white;
  font-weight: 600;
  background: #0066ff;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  margin-right: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'ibm plex sans', sans-serif;

}

.dangerbutton {
  border-radius: 25px;
  height: 30px;
  font-size: 1.1rem;
  color: white;
  font-weight: 600;
  background: #EF476F;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  margin-right: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'ibm plex sans', sans-serif;

}

.disabledbutton {
  border-radius: 25px;
  height: 30px;
  font-size: 1.1rem;
  color: white;
  font-weight: 600;
  background: #7f7f7f;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  margin-right: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'ibm plex sans', sans-serif;

}

.actionbutton {
  border-radius: 20px;
  height: 20px;
  font-size: 0.9rem;
  color: white;
  font-weight: 400;
  background: #0066ff;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'ibm plex sans', sans-serif;

}

.checkinbutton {
  border-radius: 25px;
  height: 30px;
  font-size: 1.1rem;
  color: white;
  font-weight: 600;
  background: #06D6A0;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  margin-right: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'ibm plex sans', sans-serif;

}



.signupbutton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0rem;
  padding-bottom: 2rem;
  max-width: 100%;
  color : rgb(0,102,255);
  font-family: 'ibm plex sans', sans-serif;

}

.adminbutton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0rem;
  padding-bottom: 2rem;
  max-width: 100%;
  color : rgb(172, 172, 172);
  font-family: 'ibm plex sans', sans-serif;

}

#button{
  padding-bottom: 1.5rem;
}

#facebookIcon{
  background-image: url("https://image.flaticon.com/icons/svg/145/145802.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#twitterIcon{
  background-image: url("https://image.flaticon.com/icons/svg/145/145812.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#googleIcon{
  background-image: url("https://image.flaticon.com/icons/svg/145/145804.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#iconGroup{
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

#iconGroup a{
  margin: 0 0.6rem;
}

#alternativeLogin {
   text-align: center;
   padding-top: 2rem;
   margin-top: 1.5rem;
}
